import { AppProviders } from "../../components/UIComponents/AppProviders";
import { BaseCompanyList } from "../../components/UIComponents/BaseCompanyList";
import { BusinessFormationCompany } from "./verticals/BusinessFormationCompany";
import { styles } from "./verticals/pageLayoutStyles";

export const App = () => (
	<AppProviders>
		<BaseCompanyList styles={styles}>
			<BusinessFormationCompany />
		</BaseCompanyList>
	</AppProviders>
);
