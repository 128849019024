import { AmazonProduct } from "../src/components/Verticals/Amazon/AmazonProduct";
import { AutoWarrantyCompany } from "../src/components/Verticals/AutoWarranty/AutoWarrantyCompany";
import { BestBanksCompany } from "../src/components/Verticals/BestBanks/BestBanksCompany";
import { CreditAssistanceCompany } from "../src/components/Verticals/CreditAssistance/CreditAssistanceCompany";
import { DemoGridTCR } from "../src/components/Verticals/DemoGridTCR/DemoGridTCR";
import { GoldIraCompany } from "../src/components/Verticals/GoldIras/GoldIraCompany";
import { GridLayoutAlpha } from "../src/components/Verticals/GridLayoutAlpha/GridLayoutAlpha";
import { GridLayoutBeta } from "../src/components/Verticals/GridLayoutBeta/GridLayoutBeta";
import { HomeWarrantyCompany } from "../src/components/Verticals/HomeWarranty/HomeWarrantyCompany";
import { IncorporationServicesCompany } from "../src/components/Verticals/IncorporationServices/IncorporationServicesCompany";
import { TaxAssistanceCompany } from "../src/components/Verticals/TaxAssistance/TaxAssistanceCompany";
import { ALL_CONFIG_VERTICALS, TCR_DOMAIN } from "./constants";
import {
	BUSINESS_BANK_ACCOUNTS,
	CDS_PATH,
	CHECKING_ACCOUNTS_PATH,
	SAVINGS_ACCOUNTS_PATH,
} from "./site/bestbanks";
import { CREDIT_ASSISTANCE_PATH } from "./site/bestcreditreviews";
import { GOLD_IRA_PATH } from "./site/bestiras";
import { TAX_ASSISTANCE_PATH } from "./site/besttaxreviews";
import { AUTO_WARRANTY_PATH, HOME_WARRANTY_PATH } from "./site/bestwarranty";
import {
	BACKGROUND_SEARCH_PATH,
	COFFEE_CLUBS_PATH,
	COSMETICS_PATH,
	FLOWER_DELIVERY_PATH,
	HAIR_GROWTH_PATH,
	INCORPORATION_SERVICES_PATH,
	INVISIBLE_BRACES_PATH,
	LANGUAGE_LEARNING_PATH,
	TEETH_WHITENING_PATH,
	WEIGHT_LOSS_PATH,
	WINE_CLUBS_PATH,
} from "./site/favy";
import {
	BUSINESS_INSURANCE_PATH,
	IR_PHONE_INSURANCE_PATH,
	TRAVEL_INSURANCE_PATH,
	VISION_INSURANCE_PATH,
} from "./site/insuranceranked";

export const FIXED_WIDTH_CARD_VERTICALS = [
	// Favy
	HAIR_GROWTH_PATH,
	INVISIBLE_BRACES_PATH,
	TEETH_WHITENING_PATH,
	WINE_CLUBS_PATH,
	COSMETICS_PATH,
	BACKGROUND_SEARCH_PATH,
	COFFEE_CLUBS_PATH,
	FLOWER_DELIVERY_PATH,
	WEIGHT_LOSS_PATH,
	INCORPORATION_SERVICES_PATH,
	// Insurance Ranked
	TRAVEL_INSURANCE_PATH,
	BUSINESS_INSURANCE_PATH,
	// Best Credit Reviews
	CREDIT_ASSISTANCE_PATH,
	// Best Tax Reviews
	TAX_ASSISTANCE_PATH,
	// Best Warranty
	AUTO_WARRANTY_PATH,
	HOME_WARRANTY_PATH,
];

export const GRID_LAYOUT_PATHS_WITH_COMPONENTS = {
	/**
	 * Best IRA's (bi)
	 */
	// http://local.bestiras.com/gold-ira
	[GOLD_IRA_PATH]: GoldIraCompany,

	/**
	 * Favy (fvy)
	 */
	// http://local.favy.com/hair-growth
	[HAIR_GROWTH_PATH]: GridLayoutBeta,
	// http://local.favy.com/invisible-braces
	[INVISIBLE_BRACES_PATH]: GridLayoutBeta,
	// http://local.favy.com/teeth-whitening
	[TEETH_WHITENING_PATH]: GridLayoutBeta,
	// http://local.favy.com/wine-clubs
	[WINE_CLUBS_PATH]: GridLayoutBeta,
	// http://local.favy.com/cosmetics
	[COSMETICS_PATH]: AmazonProduct,
	// http://local.favy.com/background-search
	[BACKGROUND_SEARCH_PATH]: GridLayoutBeta,
	// http://local.favy.com/coffee-clubs
	[COFFEE_CLUBS_PATH]: GridLayoutBeta,
	// http://local.favy.com/flower-delivery
	[FLOWER_DELIVERY_PATH]: GridLayoutBeta,
	// http://local.favy.com/language-learning
	[LANGUAGE_LEARNING_PATH]: GridLayoutBeta,
	// http://local.favy.com/weight-loss
	[WEIGHT_LOSS_PATH]: GridLayoutBeta,
	// http://local.favy.com/incorporation-services
	[INCORPORATION_SERVICES_PATH]: IncorporationServicesCompany,

	/**
	 * Insurance Ranked (ir)
	 */
	// http://local.insuranceranked.com/vision-insurance
	[VISION_INSURANCE_PATH]: GridLayoutAlpha,
	// http://local.insuranceranked.com/phone-insurance
	[IR_PHONE_INSURANCE_PATH]: GridLayoutAlpha,
	// http://local.insuranceranked.com/travel-insurance
	[TRAVEL_INSURANCE_PATH]: GridLayoutAlpha,
	// http://local.insuranceranked.com/business-insurance
	[BUSINESS_INSURANCE_PATH]: GridLayoutAlpha,

	/**
	 * Best Credit Reviews (bcr)
	 */
	// http://local.bestcreditreviews.com/credit-assistance
	[CREDIT_ASSISTANCE_PATH]: CreditAssistanceCompany,

	/**
	 * Best Tax Reviews (btr)
	 */
	// http://local.besttaxreviews.com/tax-assistance
	[TAX_ASSISTANCE_PATH]: TaxAssistanceCompany,

	/**
	 * Best Warranty (bw)
	 */
	// http://local.bestwarranty.com/auto-warranty
	[AUTO_WARRANTY_PATH]: AutoWarrantyCompany,
	// http://local.bestwarranty.com/home-warranty
	[HOME_WARRANTY_PATH]: HomeWarrantyCompany,

	/**
	 * Best Banks (bb)
	 */
	// http://local.bestbanks.com/savings-accounts
	[SAVINGS_ACCOUNTS_PATH]: BestBanksCompany,
	// http://local.bestbanks.com/cds
	[CDS_PATH]: BestBanksCompany,
	// http://local.bestbanks.com/business-banking
	[BUSINESS_BANK_ACCOUNTS]: BestBanksCompany,
	// http://local.bestbanks.com/checking-accounts
	[CHECKING_ACCOUNTS_PATH]: BestBanksCompany,
};

export const DEFAULT_GRID_LAYOUT = GridLayoutBeta;

export const getGridLayoutForVertical = (vertical, isAmazonVertical) => {
	if (isAmazonVertical) {
		return AmazonProduct;
	}

	// if 'vertical' is not defined anywhere amongst the keys of all the vertical site configs OR within the grid config, then fallback to rendering a grid config
	if (!ALL_CONFIG_VERTICALS.includes(vertical)) {
		return DOMAIN === TCR_DOMAIN ? DemoGridTCR : DEFAULT_GRID_LAYOUT;
	}

	return GRID_LAYOUT_PATHS_WITH_COMPONENTS?.[vertical] ?? null;
};
