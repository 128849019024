import { useContext, useRef } from "react";
import { CompanyContext } from "@/components/CompanyList/CompanyContext";
import * as constants from "@/config/constants";
import { getIsNotUserFiltered } from "@/selectors";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";
import tw from "twin.macro";

import { BaseBenefitsList } from "../../../components/BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../../components/BaseComponents/BaseCompanyLogo";
import BaseCompanyName from "../../../components/BaseComponents/BaseCompanyName";
import BaseCouponDescription from "../../../components/BaseComponents/BaseCouponDescription";
import { BaseFeaturedCompanyHeader } from "../../../components/BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../../components/BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../../components/BaseComponents/BaseLinkWrapper";
import BaseServiceFlag from "../../../components/BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../../components/BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../../components/BaseComponents/BaseVisitLink";
import { getStyles } from "./styles";

export const BusinessFormationCompany = () => {
	const { company, companyIndex, isFeaturedCompany } =
		useContext(CompanyContext);
	const { isNotUserFiltered } = useSelector(getIsNotUserFiltered);
	const styles = getStyles({ isFeatured: isFeaturedCompany });
	const awardTextMobilePadding = company.fields.awardText;
	const nodeRef = useRef(null);

	return (
		<Transition
			nodeRef={nodeRef}
			in
			appear={!isNotUserFiltered}
			timeout={constants.FILTERING_TRANSITION_DURATION}
			unmountOnExit
		>
			<BaseLinkWrapper css={styles.layoutWrapper}>
				<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
				<div css={styles.rootGrid.main}>
					<BaseServiceFlag style={styles.serviceFlag} />
					{/* Column 1 */}
					<div css={styles.col1}>
						<div
							css={styles.logoWrapper}
							className={awardTextMobilePadding && "tw-pt-5 lg:tw-pt-0"}
						>
							<BaseCompanyLogo style={styles.companyLogo} />
						</div>
						<BaseGridRatingHorizontal
							style={styles.ratingDesktop}
							starSize={"small"}
						/>
					</div>
					{/* Column 2 */}
					<div css={styles.col2}>
						<div>
							<BaseCompanyName style={styles.companyName} />
						</div>
						<BaseGridRatingHorizontal style={styles.ratingMobile} />
						<div>
							<BaseBenefitsList {...styles.benefitsList} />
						</div>
					</div>
					{/* Column 3 */}
					<div css={styles.col3}>
						<BaseCouponDescription style={styles.couponDescription} />
						<div
							css={tw`w-full flex flex-col gap-4 items-center lg:items-center lg:gap-[1.125rem]`}
						>
							{companyIndex === 1 ? (
								<BaseSocialProof {...styles.socialProof} />
							) : null}
							<BaseVisitLink {...styles.visitLink}>
								Start Your LLC
							</BaseVisitLink>
						</div>
					</div>
				</div>
			</BaseLinkWrapper>
		</Transition>
	);
};
