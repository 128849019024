import { css } from "@emotion/react";
import tw from "twin.macro";

export const getStyles = ({ isFeatured }) => ({
	layoutWrapper: css`
		${tw`block rounded-lg overflow-hidden mb-4`}
		${isFeatured ? tw`border-2 border-solid border-[#006382]` : tw``}
	`,
	featuredCompanyHeader: {
		wrapper: "tw-p-2 tw-bg-[#37474F] tw-text-center",
		contentWrapper:
			"tw-leading-6 tw-font-semibold tw-text-xs tw-text-white tw-tracking-[-0.00875rem] xs:tw-text-sm lg:tw-text-base lg:tw-tracking-[-0.01rem]",
	},
	rootGrid: {
		main: css`
			${tw`relative bg-white flex flex-col p-4 lg:flex-row lg:py-6 lg:pr-6 lg:pl-16`}
		`,
	},
	col1: css`
		${tw`relative w-full flex justify-center items-center lg:w-[20%] lg:justify-center lg:mr-6 [&>a]:w-full [&>a]:h-full [&>a]:flex [&>a]:items-center [&>a]:justify-center`}
	`,
	col2: css`
		${tw`w-full flex flex-col justify-center items-start mb-4 mx-auto max-w-[400px] md:max-w-[500px] lg:max-w-none lg:w-[48%] lg:mb-0 lg:mr-6`}
	`,
	col3: css`
		${tw`gap-4 flex flex-col w-full mx-auto max-w-[400px] md:max-w-[500px] lg:max-w-none lg:gap-6 lg:justify-center lg:flex-col-reverse lg:w-[26%]`}
	`,

	// Col1
	serviceFlag: {
		wrapper: css`
			${tw``}
		`,
		flag: css`
			${tw`absolute top-[30px] left-4 flex justify-center items-center bg-[#212529] w-7 h-7 rounded-md leading-[20px] text-white font-extrabold shadow-lightShadow lg:top-1/2 transform -translate-y-1/2`}
		`,
		number: css`
			${tw`m-0 text-white font-medium leading-5 text-sm`}
		`,
		text: css`
			${tw`absolute top-4 left-12 flex justify-center items-center bg-[#006382] text-white text-[13px] py-1.5 pl-3 pr-4 leading-4 font-semibold lg:top-6 lg:left-0`}
			clip-path: polygon(100% 0, calc(100% - 10px) 50%, 100% 100%, 0 100%, 0 0);
		`,
	},
	logoWrapper: css`
		${tw``}
	`,
	companyLogo: {
		picture: css`
			${tw`w-[120px] h-[120px] xs:w-40 xs:h-[120px] -mt-4 px-1.5 flex justify-center lg:w-40 lg:px-4 lg:mt-0`}
		`,
		image: [
			css`
				${tw`w-full h-full object-contain`}
			`,
		],
	},
	ratingDesktop: {
		wrapper: css`
			${tw`hidden lg:flex absolute rounded-lg items-center px-3 py-2.5 bottom-0`}
			transform: none;
			box-shadow:
				0px 0px 1px 0px rgba(0, 0, 0, 0.24),
				0px 2px 4px 0px rgba(0, 0, 0, 0.1);
		`,
		score: css`
			${tw`font-bold text-[#212529] mr-2 text-[2rem] tracking-[-0.1rem] leading-9`}
		`,
		stars: css`
			${tw`flex flex-col`}
		`,
		starColor: css`
			.MuiRating-iconFilled {
				${tw`text-[#f68700] mb-1`}
			}
		`,
		count: css`
			${tw`font-semibold text-[#006382] text-xs leading-4`}
		`,
	},

	// Col2
	companyName: {
		text: css`
			${tw`text-lg font-bold mb-2 text-[#212529] leading-6 tracking-[-0.01125rem] lg:text-xl lg:leading-8 lg:tracking-[-0.025rem] lg:mb-4`}
		`,
	},
	ratingMobile: {
		wrapper: css`
			${tw`mb-4 flex relative rounded-lg p-2 items-center w-full max-w-[296px] lg:hidden`}
			transform: none;
			box-shadow:
				0px 0px 1px 0px rgba(0, 0, 0, 0.24),
				0px 2px 4px 0px rgba(0, 0, 0, 0.1);
		`,
		score: css`
			${tw`font-bold text-[#212529] leading-[100%] text-2xl mr-2`}
		`,
		stars: css`
			${tw`flex w-full`}
		`,
		starColor: css`
			.MuiRating-iconFilled {
				${tw`text-[#f68700]`}
			}
		`,
		count: css`
			${tw`ml-2 flex-grow text-sm font-semibold leading-[100%] text-[#006382] text-right self-center`}
		`,
	},
	benefitsList: {
		wrapper: "tw-w-full tw-px-0",
		list: "tw-w-full tw-mb-0 tw-p-0",
		listItem:
			"tw-w-full tw-text-sm tw-leading-6 tw-text-[#212529] tw-pl-6 tw-mb-2 last:tw-mb-0 lg:tw-text-sm tw-bg-[url(/public/howtostartanllc/images/svg/check.svg)] [background-position:left_top_5px] tw-bg-no-repeat [background-size:16px]",
	},

	// Col3
	visitLink: {
		wrapper:
			"tw-inline-flex tw-w-full tw-justify-center tw-p-2 tw-bg-[#006382] tw-rounded-lg tw-cursor-pointer lg:tw-py-3",
		contentWrapper:
			"tw-text-white tw-font-bold tw-leading-6 tw-text-sm md:tw-text-base",
	},
	socialProofSection: css`
		${tw`flex-shrink lg:flex-shrink-0 flex-grow lg:flex-grow-0 mb-0 mt-4 lg:mt-0`}
		flex-basis: 100%;
		@media (min-width: 1024px) {
			flex-basis: 0;
		}
	`,
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-max-w-fit tw-self-center tw-rounded-lg tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none md:after:tw-w-3 md:after:tw-h-3 after:tw-top-full",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#F68700] tw-text-2xl md:tw-text-[32px]",
		content:
			"tw-min-w-[100px] tw-max-w-[100px] tw-font-semibold tw-text-[0.6875rem] tw-text-left tw-text-[#22293A]",
	},
	couponDescription: {
		wrapper: css`
			${tw`relative flex items-center justify-center p-2 border border-dashed border-[#006382] rounded-lg lg:p-[18px]`}
		`,
		icon: {
			wrapper: css`
				${tw`absolute top-0 right-5 flex items-center justify-center bg-white px-2 lg:px-1.5`}
				transform: translateY(-50%);
			`,
			content: css`
				${tw`text-[#006382] w-[15px] h-[15px] lg:w-5 lg:h-5`}
			`,
		},
		content: css`
			${tw`min-w-[144px] leading-6 font-semibold text-sm text-center text-[#006382] lg:text-base lg:tracking-[-0.01rem]`}
		`,
	},
});
