export const styles = {
	mainContainer: "tw-canister tw-pb-4",
	companyListContainer: "tw-flex tw-w-full tw-flex-col",
	pageLink:
		"tw-inline-flex tw-text-llc-gray-900 tw-bg-white tw-capitalize tw-pt-2.5 tw-pb-3 tw-px-4 tw-text-sm tw-font-medium tw-leading-[normal] tw-shadow-[0px_10px_16px_0px_rgba(60,57,53,0.06),0px_1px_0px_0px_rgba(60,57,53,0.04),0px_0px_8px_0px_rgba(60,57,53,0.04)] tw-rounded-lg",
	paginationContainer: "tw-mx-auto tw-my-[28px] tw-flex tw-gap-2",
	previousLink: "tw-mr-6",
	nextLink: "tw-ml-6",
	activeLink: "!tw-bg-llc-header-gray !tw-text-white",
	disabledLink: "tw-opacity-50 tw-cursor-not-allowed",
};
